<div class="layout-footer flex flex-column md:flex-row align-items-center justify-content-between p-6 bg-primary">
    <div class="flex flex-column gap-4 justify-content-center p-5">
        <div class="flex">
            <img [src]="brandLogoWhite" [alt]="brandLogoAlt" height="20" class="mt-1" />
            <div class="text-2xl text-white ml-3 font-bold">{{ brandNameString }}</div>
        </div>
        <div class="flex flex-column gap-2">
            <a
                *ngIf="cookieLink"
                [href]="cookieLink.url"
                [attr.target]="cookieLink.target ? cookieLink.target : '_blank'"
                class="footer-link text-white">
                {{ cookieLink.title }}
            </a>

            <a
                *ngIf="privacyPolicyLink"
                [href]="privacyPolicyLink.url"
                [attr.target]="privacyPolicyLink.target ? privacyPolicyLink.target : '_blank'"
                class="footer-link text-white">
                {{ privacyPolicyLink.title }}
            </a>
        </div>
        <div class="flex justify-content-center">
            <ng-container *ngFor="let link of brandMaintenanceUrl">
                <a [href]="link.url" [attr.target]="link.target" class="service-button-link">
                    <p-button class="service-button" [outlined]="true" [label]="link.title"></p-button>
                </a>
            </ng-container>
        </div>
    </div>
    <div class="flex flex-column gap-4 p-5">
        <div class="flex justify-content-center">
            <div class="text-xs text-white">{{ 'footer.socialMedia' | translate }}</div>
        </div>
        <div class="flex justify-content-center" *ngIf="socialLinks">
            <ng-container *ngFor="let link of socialLinks">
                <a [href]="link.url" [attr.target]="link.target" class="social-icon">
                    <i
                        class="fab"
                        [ngClass]="{
                            'fa-facebook': link.url.includes('facebook'),
                            'fa-instagram': link.url.includes('instagram'),
                            'fa-youtube': link.url.includes('youtube'),
                            'fa-linkedin-in': link.url.includes('linkedin'),
                            'fa-brands fa-tiktok': link.url.includes('tiktok')
                        }"
                    ></i>
                </a>
            </ng-container>
        </div>
    </div>
    <div class="flex flex-column gap-4 h-full p-5">
        <div class="flex-grow-1"></div>
        <div class="text-base text-white">(C) {{ brandNameString }} {{ currentYear }}</div>
    </div>
</div>
