import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutService } from './service/app.layout.service';
import { BrandService } from '../../application/services/brand.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ContentService } from '../../application/services/content.service';
import { ContentProcessor } from '../../application/content.processor';
import { HttpParams } from '@angular/common/http';
import { take } from 'rxjs';
import { SettingsResponse } from 'src/common/models/umbraco-responses/SettingsResponse';
import { SettingsModel } from '../../application/models/SettingsModel';
import { Link } from '../../application/models/Link';
import { HelperService } from '../services/helper.service';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    standalone: true,
    imports: [CommonModule, ButtonModule, TranslateModule],
})
export class AppFooterComponent {
    settings: SettingsModel | undefined;
    currentYear: number = new Date().getFullYear();
    constructor(
        public brandService: BrandService,
        private contentService: ContentService,
        private helperService: HelperService,
        public contentProcessor: ContentProcessor,
        public layoutService: LayoutService,
    ) {}

    get brandLogoWhite(): string {
        return this.settings?.brandLogo[0]?.url || '';
    }

    get brandLogoAlt(): string {
        return this.settings?.brandLogo[0]?.altText || '';
    }

    get brandNameString(): string {
        return this.settings?.brandName || '';
    }

    get socialLinks(): Link[] {
        return this.settings?.socialLinks || [];
    }

    get brandMaintenanceUrl(): Link[] {
        return this.settings?.brandMaintenanceUrl || [];
    }

    get privacyPolicyLink(): Link | undefined {
        return this.settings?.privacyPolicyLink?.[0] ?? undefined;
    }

    get cookieLink(): Link | undefined {
        return this.settings?.cookieLink?.[0] ?? undefined;
    }

    ngOnInit() {
        this.brandService.brandId$.subscribe({
            next: (brandId) => {
                const params = new HttpParams({
                    fromObject: {
                        filter: 'contentType:settings',
                        fields: 'properties[$all]',
                        fetch: `descendants:${brandId}`,
                    },
                });

                this.contentService
                    .getContentItemsFromQuery<{ total: number; items: SettingsResponse[] }>(
                        params,
                        this.helperService.currentLanguage,
                    )
                    .pipe(take(1))
                    .subscribe({
                        next: (result) => {
                            if (result.items.length > 0) {
                                this.settings = this.contentProcessor.getSettingsFromSettingsResponse(result.items[0]);
                            }
                        },
                    });
            },
        });
    }
}
