import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import { LayoutService } from './service/app.layout.service';
import { AuthenticationService } from '../services/authentication.service';
import { BrandService } from '../../application/services/brand.service';
import { HttpParams } from '@angular/common/http';
import { SettingsResponse } from 'src/common/models/umbraco-responses/SettingsResponse';
import { ContentService } from '../../application/services/content.service';
import { ContentProcessor } from '../../application/content.processor';
import { Subscription, take } from 'rxjs';
import { SettingsModel } from '../../application/models/SettingsModel';
import { HelperService } from '../services/helper.service';
import { Language } from 'src/common/models/language';
import { UmbracoTranslateLoader } from '../../application/umbraco-translate-loader';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    standalone: true,
    imports: [RouterLink, NgClass, TranslateModule, DropdownModule, FormsModule, CommonModule],
})
export class AppTopBarComponent implements OnInit, OnDestroy {
    items!: MenuItem[];
    dropDownItems: MenuItem[] = [];
    settings: SettingsModel | undefined;

    @ViewChild('menuButton') menuButton!: ElementRef;
    @ViewChild('topBarMenuButton') topbarMenuButton!: ElementRef;
    @ViewChild('topBarMenu') menu!: ElementRef;

    languageOptions: Language[] = [];
    translatedLanguageOptions: Language[] = [];
    selectedLanguage: string | null = this.currentLanguage;
    private languageChangeSubscription!: Subscription;

    constructor(
        public layoutService: LayoutService,
        private brandService: BrandService,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private helperService: HelperService,
        private contentService: ContentService,
        public contentProcessor: ContentProcessor,
        public router: Router,
        private umbracoTranslateLoader: UmbracoTranslateLoader,
    ) {}

    ngOnInit() {
        // Subscribe to language updates
        this.umbracoTranslateLoader.languages$.subscribe((languages: Language[]) => {
            this.languageOptions = languages;
            this.translateLanguageOptions();
        });
        this.umbracoTranslateLoader.getConfiguredLanguages();
        const storedLanguage = localStorage.getItem('selectedLanguage');
        if (storedLanguage) {
            this.changeLanguage(storedLanguage);
        } else {
            this.selectedLanguage = this.currentLanguage;
        }
        this.languageChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            this.translateLanguageOptions();
        });

        this.brandService.brandId$.subscribe({
            next: (brandId) => {
                const params = new HttpParams({
                    fromObject: {
                        filter: 'contentType:settings',
                        fields: 'properties[$all]',
                        fetch: `descendants:${brandId}`,
                    },
                });

                this.contentService
                    .getContentItemsFromQuery<{ total: number; items: SettingsResponse[] }>(
                        params,
                        this.helperService.currentLanguage,
                    )
                    .pipe(take(1))
                    .subscribe({
                        next: (result) => {
                            if (result.items.length > 0) {
                                this.settings = this.contentProcessor.getSettingsFromSettingsResponse(result.items[0]);
                            }
                        },
                    });
            },
        });
    }

    ngOnDestroy(): void {
        if (this.languageChangeSubscription) {
            this.languageChangeSubscription.unsubscribe();
        }
    }

    async logOut() {
        await this.authenticationService.logout();
    }

    translateLanguageOptions(): void {
        this.translatedLanguageOptions = this.languageOptions.map((option) => ({
            isoCode: option.isoCode,
            name: this.translateService.instant(option.name),
        }));
    }

    changeLanguage(languageCode: string): void {
        this.translateService.use(languageCode);
        this.selectedLanguage = languageCode;
        localStorage.setItem('selectedLanguage', languageCode);
    }

    async edit() {
        await this.router.navigate(['/admin/group']);
    }

    async changePassword() {
        await this.router.navigate(['/profile/password-change']);
    }

    goToManuals(): void {
        if (this.settings?.manualsPage) {
            const { url, target } = this.settings.manualsPage;
            window.open(url, target || '_self');
        }
    }

    goToOffers(): void {
        if (this.settings?.offersPage) {
            const { url, target } = this.settings.offersPage;
            window.open(url, target || '_self');
        }
    }

    get brandLogoDark(): string {
        return this.brandService.getBrandLogo('dark');
    }

    get brandLogoAlt(): string {
        return this.translateService.instant('brandLogoAlt', this.brandService.brandNameString);
    }

    get brandNameString(): string {
        return this.settings?.brandName || '';
    }

    get currentLanguage(): string {
        return this.translateService.currentLang;
    }
}
