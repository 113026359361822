import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TranslateService } from '@ngx-translate/core';

import { BrandService } from '../../application/services/brand.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfirmEmailRequest } from '../../application/models/confirm-email-request';
import { ConfirmEmailResponse } from '../../application/models/confirm-email-response';
import { ContentService } from '../../application/services/content.service';
import { BrandUrlService } from '../../application/services/brandurl.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(
        public oidcSecurityService: OidcSecurityService,
        private brandService: BrandService,
        private translateService: TranslateService,
        private http: HttpClient,
        private contentService: ContentService,
        private brandUrlService: BrandUrlService
    ) {
        // this.oidcSecurityService.userData$.subscribe({
        //     next: (userData) => console.log('userData', userData),
        // });
    }

    login(): void {
        this.oidcSecurityService.authorize(undefined, {
            customParams: {
                brand: this.brandService.brandNameString.toLowerCase(),
                language: this.translateService.currentLang,
            },
        });
    }

    register(requireVIN: boolean): void {
        const redirectUrl = `${window.location.origin}/auth/registration-email-sent`;
        const nonce = `d7e67640894eccdf06afc7401c5c427686vtrNIKh`;
        const returnUrl = `/umbraco/delivery/api/v1/security/member/authorize`;
        const state = `c92fe87bcd222703a115edf275b8b7966b45CmWQY`;
        const codeChallenge = `gaCSy3kpu-L_3PZiDUe_aj058ojn37Cev9h6I-1xGh4`;
        const brand = this.brandService.brandNameString.toLowerCase();
        const language = this.translateService.currentLang;

        window.location.href = `${this.brandUrlService.brandBackendUrl}/register?ReturnUrl=${returnUrl}?client_id=umbraco-member&redirect_url=${redirectUrl}&response_type=code&scope=openid&nonce=${nonce}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256&brand=${brand}&language=${language}&requireVIN=${requireVIN}`;
    }

    async logout() {
        this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => console.log(result));
    }

    confirmEmail(confirmEmailRequest: ConfirmEmailRequest): Observable<ConfirmEmailResponse> {
        const url = `${this.contentService.url}umbraco/api/RegisterApi/ConfirmAccount`;
        const body = new HttpParams()
            .set('email', confirmEmailRequest.email)
            .set('brand', confirmEmailRequest.brand)
            .set('language', this.translateService.currentLang)
            .set('token', confirmEmailRequest.token);

        return this.http.post<ConfirmEmailResponse>(url, body);
    }
}
